import React, { useState } from 'react';

export default function ({ children = [], onDone = () => { } }) {
    const [stage, setStage] = useState(0);
    const parsedChildren = parseChildren(children);
    const Component = parseStage(parsedChildren, stage);


    const stepStage = (val, cb) => () => { setStage((stage + val) % parsedChildren.length); cb && cb(stage + val) };

    return <Component
        next={stepStage(1, newStage => newStage === parsedChildren.length && onDone())}
        back={stepStage(-1)}
        setStage={setStage}
        stages={parsedChildren}
    />
}

function parseStage(stages, idx) {
    switch (true) { //case by stage type
        case Array.isArray(stages): return stages[idx] || function () { return <div /> };
        case typeof (stages) === 'function': return stages;
        default: throw new Error('invalid_stage_type')
    }
}

const parseChildren = children => !Array.isArray(children) ? children : children.filter(c => c)