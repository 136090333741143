import React, { useContext } from 'react';
const ActionsContext = React.createContext();

export default {
    ActionsProvider: ({ actions, children }) =>
        <ActionsContext.Provider value={actions}>
            {children}
        </ActionsContext.Provider>,
    useActions: function (path) {
        const actions = useContext(ActionsContext);
        return path ? actions[path] : actions
    }
}