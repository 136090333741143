import flyd from 'flyd';
import deepCompare from './deep-compare';

export const debounce = ms => s => {
    let timeout
    return flyd.combine((s, self) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => self(s.val), ms)
    }, [s])
}

export const distinctUntilChanged = (compare = deepCompare) => s => flyd.combine((s, self) => !compare(s.val, self.val) && self(s.val), [s]);

