
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */
    
import Features from './features';
import Models from './models';
import Tools from './tools';

export default {
        Features,		Models,		Tools
}
