import React, { useState } from 'react';
import styles from '../../style.module.css';
import widgets from '../../../widgets';

const { Input, Title, Button } = widgets;

export default function ({ profile, onSubmit }) {
    const { actions } = profile;

    const [mfa, setMfa] = useState('');

    const auth = () => actions.mfa({ mfa }).then(onSubmit);

    return <div>
        <Title>MFA</Title>
        <div className={styles['form']}>
            <Input value={mfa} placeholder='mfa' onChange={setMfa} />
        </div>
        <div className={styles['button']}><Button onEnter onClick={auth}>SUBMIT</Button></div>
    </div>
}