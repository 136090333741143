import React, { useState } from 'react';
import { ModelRuleSet } from '@combotag/public-common/dist/libs/AppValidator';
import { capitalize } from '@combotag/public-common/dist/libs/utils';

export default ({ name = '', Form, ruleset = new ModelRuleSet() }) =>
    ({ viewFeature }) => viewFeature(`build${capitalize(name)}Form`, update =>
        function ({ initial = {}, ...props }) {
            const [state, setState] = useState(initial);
            const [errors, setErrors] = useState({});

            const setters = Object.keys(state)
                .map(key => [key, value => {
                    setState({ ...state, [key]: value });
                    ruleset.validate(state, key)
                        .then(([{ errors = [] } = {}]) => errors.pop())
                        .then((error = {}) => setErrors({ ...errors, [key]: (error.message || error.validationName) }))
                }])
                .reduce((acc, [key, val]) => ({ ...acc, [`set${capitalize(key)}`]: val }), {})

            return <Form state={state} errors={errors} setters={setters} {...props} />;
        })