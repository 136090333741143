import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) => {
    const { custom = {
        text: { primary: '#fe0072' }
    } } = palette;

    return ({
        error: {
            color: 'red',
            height: 30,
            fontStyle: 'italic'
        },
        form: {

        },
        option: {

        },
        button: {
            marginTop: 80,
            '& div': {
                '& input': {
                    width: 299,
                    height: 71,
                    fontSize: 24
                }
            }
        },
        link: {
            color: custom.text.primary,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        titleContainer: {
            '& div': {
                display: 'inline-block'
            }
        }
    })
})