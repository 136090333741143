import React from 'react';
import styles from './style.module.css';
import './svg.css';

export default function ({ children, label }) {
    const display = children < 10 ? `0${children}` : children

    return <div className={styles['time-box-container']}>
        <PinkBox width={95} />
        <div className={styles['time-value']}>{display}</div>
        <div>{label}</div>
    </div>
}

const PinkBox = ({ width, ...style }) => <div className={styles['time-box']} style={style}>
    <svg id="Layer_1"
        width={width}
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75 81.16">
        <defs>
        </defs><title>CHEQ_-climate_wacht</title>
        <rect className="cls-1" width="75" height="40.42" />
        <rect className="cls-2" y="40.74" width="75" height="40.42" /><rect className="cls-3" y="39.92" width="75" height="1.5" /><rect className="cls-3" x="61.48" y="39.25" width="9.42" height="2.53" transform="translate(106.7 -25.67) rotate(90)" />
        <rect className="cls-3" x="2.54" y="39.25" width="9.42" height="2.53" transform="translate(47.76 33.27) rotate(90)" />
    </svg>
</div>

//<style></style>