import React, { useContext, useEffect, useState } from 'react';
import deepCompare from '../../meiosis/deep-compare';

const MeiosisContext = React.createContext();

export default {
    MeiosisProvider: ({ states, children }) =>
        <MeiosisContext.Provider value={states}>
            {children}
        </MeiosisContext.Provider>,
    useMeiosis: (selector = state => state, initial) => {
        const states = useContext(MeiosisContext);
        const [state, setState] = useState(selector(states() || {}) || initial);

        useEffect(() => {
            const stream = states.map(selector)
                .map(s => s !== state && setState(s));

            return () => stream.end(true);
        }, []);

        return state;
    }
}

const compare = (a, b) => {
    const type = typeof a === typeof b && typeof a;

    if (!type) return false;
    if (Array.isArray(a) && Array.isArray(b)) { }
}