import axios from 'axios';
import flyd from 'flyd';
import filter from 'flyd/module/filter';

export default class {
    constructor(name, { baseURL, baseRoute = name } = {}) {
        /** Properties */
        this.name = name;
        this.baseRoute = baseRoute;
        this.state = { services: {}, loads: [], errors: [] , info : [] };
        this.stream = null;
        // this.stream = flyd.scan((state, mutation) => mutation(state), this.state, flyd.stream());

        this.axios = axios;

        /** Services */
        this.http = axios.create({
            baseURL: `${baseURL}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        /** 
        * Interceptors 
        * if you would like to disable any interceptor, in the constructor
        * of your view model you may do:
        * this.ejectResInterceptor() \ this.ejectReqInterceptor()
        */
        this._reqInterceptor = this.http.interceptors.request.use(config => load.call(this, config.url) && ({
            ...config, withCredentials: true, headers: {
                ...config.headers, 'X-T-CSRF': localStorage.getItem('csrf')
            }
        }))

        this._resInterceptor = this.http.interceptors.response.use(
            r => unload.call(this, r.config.url) && r, error => {
                if (error.config) unload.call(this, error.config.url);
                if (error instanceof Error) throw error;
                else if (error.response.status === 403) {
                    localStorage.removeItem('authenticated')
                    window.location.reload();
                } else return error;
            },
        )
    }

    set state(newState) { this._state = { ...this.state, ...newState }; }
    get state() { return this._state };

    update(arg) {
        const { name } = this;
        const byHandler = (arg, state) => ((({
            function: cb => cb(state),
            object: obj => obj,
        })[typeof (arg)] || function () { throw Error(`argument from type ${typeof (type)} not supported by update`) }))(arg);

        return this.stream([name, state => ({ ...state, ...Object.assign(this.state, byHandler(arg, state)) })]);
    }

    buildStream(s = {}) { return ({ ...s[this.name] }); }

    errorFor(code, ttl = 5000) {
        this.update({ errors: [...this.state.errors, code] });
        setTimeout(() => this.update({ errors: this.state.errors.filter(e => e !== code) }), ttl)
    }

	infoFor(code, ttl = 5000) {
		this.update({ info: [...this.state.info, code] });
		setTimeout(() => this.update({ info: this.state.info.filter(e => e !== code) }), ttl)
	}

    isLoading(...urls) {
        return urls.length
            ? urls.every(url => this.state.loads.find(u => u.includes(url)))
            : !!this.state.loads.length;
    }

    getService(name) { return this.state.services[name]; }
    addService(name, service, safe = true) {
        if (safe && this.state.services[name]) throw Error(`model-${this.name}::service_exists`);
        if (this.stream) service.setStream(this.stream);

        this.state.services[name] = service;

        return this;
    }

    setStream(stream) {
        this.stream = stream;
        const { services = {} } = this.state;
        Object.values(services).map(service => service.setStream(stream));
        return this;
    }

    ejectResInterceptor() { this.http.interceptors.response.eject(this._resInterceptor); }
    ejectReqInterceptor() { this.http.interceptors.request.eject(this._reqInterceptor); }
    getState() { return { ...this.state }; }
    getName() { return this.name; }
    getActions() {
        return getProtoMethods.call(this, this)
            .filter(([, value]) => typeof value === 'function')
            .filter(([key]) => !privateMethods.includes(key))
            .reduce((acc, [key, action]) => ({ ...acc, [key]: acc[key] || action.bind(this) }), {});
    }

    resetState(strategiesOverride = {}) { return this.update(restObject(this.state, strategiesOverride)) }
}

const privateMethods = ['http', 'getName', 'getState', 'setStream', 'getActions', 'ejectResInterceptor', 'ejectReqInterceptor'];

function restObject(initial = {}, strategiesOverride = {}) {
    const getStrategy = val => Array.isArray(val) ? 'array' : typeof val;
    const strategies = {
        object: ({ value }) => restObject(value),
        array: () => [],
        number: () => 0,
        string: () => '',
        ...strategiesOverride
    }

    return Object.entries(initial).reduce((acc, [key, value]) => ({
        ...acc, [key]: (strategies[key] || strategies[getStrategy(value)] || function () { })({ key, value }),
    }), {})
}

function getProtoMethods(obj, result = []) {
    const proto = Object.getPrototypeOf(obj);
    const add = o => Object.entries(Object.getOwnPropertyDescriptors(o))
        .filter(([key, { value }]) => key !== 'constructor' && value && !!value.bind)
        .map(([key, { value }]) => [key, value.bind(this)])

    return proto
        ? getProtoMethods.call(this, proto, [...result, ...add(obj)])
        : result;
}

function load(arg) { return this.update({ loads: [arg, ...this.state.loads] }) || true }
function unload(arg) { return this.update({ loads: this.state.loads.filter(a => !arg.includes(a)) }) || true }