
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */
    
import AutoComplete from './auto-complete';
import Bounderies from './bounderies';
import Box from './box';
import BoxButton from './box-button';
import Button from './button';
import Checkbox from './checkbox';
import CheqLogin from './cheq-login';
import Clock from './clock';
import ColorByValue from './color-by-value';
import ColoredList from './colored-list';
import ExpendableList from './expendable-list';
import Input from './input';
import Labels from './labels';
import ModeSelector from './mode-selector';
import Pagination from './pagination';
import Particles from './particles';
import Select from './select';
import Stager from './stager';
import TabsBox from './tabs-box';
import Title from './title';
import Weather from './weather';

export default {
        AutoComplete,		Bounderies,		Box,		BoxButton,		Button,		Checkbox,		CheqLogin,		Clock,		ColorByValue,		ColoredList,		ExpendableList,		Input,		Labels,		ModeSelector,		Pagination,		Particles,		Select,		Stager,		TabsBox,		Title,		Weather
}
