import React, { useEffect } from 'react';
import styles from './style.module.css';
import widgets from '../widgets';
import forms from './forms';

const { MfaForm, UserpassForm } = forms;
const { Title, Stager } = widgets;

export default ({ Logo, useActions, useMeiosis }) => {
    const actions = useActions();
    const profile = useMeiosis(actions.profile.buildStream, {});
    const { requiredAuths, ready, errors } = profile;

    const formsData = { state: profile, actions: actions.profile };

    return ready ? <div className={styles['container']}>
        <div className={styles['app-logo']}>
            {Logo ? <Logo /> : <Title>CHEQ</Title>}
        </div>
        <div className={styles['form-container']}>
            <Stager onDone={actions.profile.isAuthenticated}>
                {requiredAuths.userpass && function ({ next }) { return <UserpassForm profile={formsData} onSubmit={next} /> }}
                {requiredAuths.mfa && function ({ next }) { return <MfaForm profile={formsData} onSubmit={next} /> }}
            </Stager>
        </div>
    </div> : <div />
}