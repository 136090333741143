
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */
    
import Base from './base';
import Profile from './profile';

export default {
        Base,		Profile
}
