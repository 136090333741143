import React from 'react';
import popup from './popup';
import redirect from './redirect';

const modes = { popup, redirect };

export default ({ mode = 'redirect', ...props }) => {
    const Component = modes[mode];

    if (!Component) throw Error(`unknown mode ${mode}`);
    else return <Component {...props} />
}