import React from 'react';
import styles from './style.module.css';
import { useTheme } from '@material-ui/core/styles';

export default function ({ page, pages, onChange, ...props }) {
    const theme = useTheme();
    const { primary, text } = theme.palette;

    const PageOption = ({ children, selected, onClick, ...props }) => <div
        {...props}
        style={{ color: selected ? primary.light : text.primary }}
        onClick={() => onClick(children)}
        className={`${styles['option']}`}>{children + 1}</div>

    return <div className={styles['container']} {...props}>
        {Array.from(Array(pages))
            .map((none, idx) => idx)
            .map(number => <PageOption onClick={onChange} selected={number === page}>{number}</PageOption>)}
    </div>
}