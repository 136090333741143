import React from 'react';
import { _String } from 'chopin-methods';
import styles from './style.module.css';

export default function ({ data, className, excludeKeys = () => true }) {
    const labels = Object.entries(data)
        .filter((([key]) => !excludeKeys.includes(key)))
        .filter(([, value]) => typeof value === 'string')
        .map(([key, value]) => [key === 'toplevel' ? 'topLevel' : key, value])
        .map(([key, value]) => [toPascal(key), value]);
    return <div className={`${styles['container']} ${className}`}>
        {labels.map(([key, value], idx) => <div key={idx} className={styles['label']}>
            <span className={styles['span-key']}>{key}</span>
            <span className={styles['span-seperator']}>:</span>
            <span className={styles['span-value']}> {value}</span>
        </div>)}
    </div>;
}

const toPascal = str => _String.camelToSnake(str).split('_').map(_String.capitalize).join(' ');