import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export default ({
    fixCenter = value => value,
    tooltipTitle,
    value = 0, steps = 10,
    display = value => `${value}%`,
}) => <Tooltip
    disableFocusListener={!tooltipTitle}
    disableHoverListener={!tooltipTitle}
    disableTouchListener={!tooltipTitle}
    title={tooltipTitle}>
        <div style={{ color: getColor(value, buildScale(steps, fixCenter)) }}>{display(value)}</div>
    </Tooltip>

const getColor = (value, scale) => `rgb(${scale[parseInt((scale.length - 1) / 100 * value)].join(',')})`;

function buildColorCalc(value) {
}

function buildScale(steps, fixCenter) {
    return Array.from(Array(steps)).map(byIdx).reverse();
    function byIdx(x, idx, src) {
        const halfWay = fixCenter(src.length / 2);
        if (idx < halfWay) return [255, 255 * (idx / steps), 0]
        else if (idx > halfWay) return [255 * (steps - idx) / steps, 255, 0]
        else return [255, 255, 0];
    }
}