import Methods from 'chopin-methods';

const { _String } = Methods;

export default ({ feature }) => class extends feature {
    constructor({ name, route = _String.camelToSnake(name), type = 'get', parseArgs = args => args,
        axiosConfig = args => undefined, baseRoute, updateState, postRequest, preRequest,avoidRequestIfTrue, onError = e => { throw e } }) {
        super(name);
        this.addMethod(function (...args) {
            const params = type === 'get' && args[0] ? `?${objToGet(...args)}` : '';
            if(avoidRequestIfTrue && avoidRequestIfTrue.call(this)){
                return Promise.resolve();
            }
            preRequest && preRequest.call(this, ...args);
            const requestMethod = localStorage.getItem(`skip.${baseRoute || this.baseRoute}/${route}`) ?
                Promise.resolve({data : JSON.parse(localStorage.getItem(`skip.${baseRoute || this.baseRoute}/${route}`))}) :
	            this.http[type](`/${baseRoute || this.baseRoute}/${route}${params}`, parseArgs(...args), axiosConfig(...args))

            return requestMethod.then(({ data },a,b,c) => {
                updateState && this.update(updateState.call(this, data, ...args))
                return postRequest && postRequest.call(this, data, ...args);
                // data && process.env.NODE_ENV !== 'production' &&  localStorage.setItem(`skip.${baseRoute || this.baseRoute}/${route}`,JSON.stringify(data)) //dev
            })
            .catch(err=>{
                onError.call(this,err,...args)
            });
        });
    }

    addMethods() { throw Error('route supports only single methods') }
    addMethod(method) { return super.addMethod(this.name, method); }
}

const objToGet = params => Object.entries(params)
    .map(([key, value]) => [_String.camelToSnake(key), encodeURIComponent(value)])
    .map(pair => pair.join('=')).join('&');


