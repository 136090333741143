import React, { useState, useRef, useLayoutEffect } from 'react';
import styles from './style.module.css';
import BoxButton from '../box-button';

export default function ({ children, defaultTab = '', background }) {
    const element = useRef(null);
    const [tab, setTab] = useState(defaultTab);
    const tabs = children.length ? children.map(buildTab) : [buildTab(children)];

    return <div className={styles['container']} ref={element}>
        <div className={`${styles['tabs-select']}`} style={{ background }}>
            {tabs.length > 1 && tabs.map((t, idx) => <BoxButton
                margin={0}
                onClick={() => setTab(t.props.name)}
                selected={tab === t.props.name}
                key={idx}>{t.props.name}</BoxButton>)}
        </div>
        <div className={styles['tab-view']}>
            {tabs.find(({ props }) => tab === props.name)}
        </div>
    </div>
}

const buildTab = child => ({
    ...child,
    name: child.props.name || child.constructor.name
})