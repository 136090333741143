import React , {useRef,useEffect} from 'react';
import stylesFile from './style.module.css';

export default ({ width = 290, height = 41, style, onChange = console.log,
	                errorMessage = '',
					value,
					focusOnMount = false,
	                styles = {},
	                renderError = props => <ErrorLabel {...props} />,
	                ...props }) =>{

	const refInput = useRef(null)

	useEffect(()=>{
		if(focusOnMount){
			refInput.current.focus();
		}
	},[focusOnMount])

	return <div className={`${stylesFile['container']} ${styles.container}`}>
		{renderError({ message: errorMessage })}
		<input
			value={value}
			ref={refInput}
			className={`${stylesFile['input']} ${styles.input}`}
			onChange={({ target }) => onChange(target.value)}
			style={{ width, height, ...style }}{...props}
		/>
	</div>
}


const ErrorLabel = ({ message }) => <div className={stylesFile['error']}>{message}</div>