import React, { useEffect } from 'react';
import styles from './style.module.css';

export default function ({ id, clickable = false, ...props }) {
    if (!id) throw Error('weather widget must have id as prop');
    useEffect(() => build(document, 'script', 'weatherwidget-io-js', clickable ? false : id))

    return <div id={id} className={styles['container']}{...props} onClick={(e => { e.preventDefault(); e.stopPropagation(); })}>
        <a className="weatherwidget-io" href="https://forecast7.com/en/35d69139d69/tokyo/" data-label_1="TOKYO" data-label_2="WEATHER" data-theme="candy" >TOKYO WEATHER</a>
    </div>
}

function initDisable(id) {
    setTimeout(() => {
        const container = document.getElementById(id);
        const iframe = container && container.getElementsByTagName('iframe')[0];

        if (iframe && iframe.parentNode) disableWidget(iframe);
        else initDisable(id);
    }, 500)
}

function disableWidget(iframe) {
    iframe.style.pointerEvents = 'none';
    iframe.style.cursor = 'auto';
    iframe.parentNode.style.cursor = 'auto';
}

function build(d, s, id, unclickable) {
    var js, fjs = d.getElementsByTagName(s)[0];
    var e = d.getElementById(id);
    if (!e) {
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://weatherwidget.io/js/widget.min.js';
        fjs.parentNode.insertBefore(js, fjs);
        if (unclickable) initDisable(unclickable);
    }
    return () => {
        setTimeout(() => {
            if (!unclickable || !d.getElementById(unclickable)) {
                e && e.parentNode.removeChild(e);
                js && js.parentNode.removeChild(js);
            }
        }, 100)
    }
}

