import React, { useRef, useState } from 'react';
import SelectField from 'react-select';

const C_BACKGROUND = '#383851';
const C_BACKGROUND_SELECTED = '#14172c';
const C_FONT = 'lightgrey';
const C_FONT_SELECTED = '#fe0072';

export default ({ style, disabled, options = [], defaultValue = options[0], optionsBy, value, onChange = console.log, ...props }) => <div style={style}>
    <SelectField
        styles={buildStyles()}
        disabled={disabled}
        isDisabled={disabled}
        defaultValue={defaultValue}
        // onChange={({ value }) => onChange(value)}
        onChange={onChange}
        value={typeof (value) === 'object' ? value : {
            label: value, value
        }}
        options={!optionsBy ? options :
            options.map(item => item[optionsBy])
                .map(option => ({ label: option, value: option }))}
        {...props}>
    </SelectField>
</div >

const buildStyles = () => ({
    control: style => ({ ...style, borderRadius: 0, borderStyle: 'none', backgroundColor: C_BACKGROUND, background: C_BACKGROUND }),
    input: style => ({ ...style, backgroundColor: C_BACKGROUND }),
    placeholder: style => ({ ...style, backgroundColor: C_BACKGROUND }),
    singleValue: style => ({ ...style, color: C_FONT }),
    option: style => ({
        ...style,
        color: 'white',
        backgroundColor: C_BACKGROUND,
        ':hover': {
            boarder: '',
            background: '#14172c',
            color: '#fe0072'
        }
    })
})