export default options => ({ feature }) => feature('tableable')
    .initializer(function () {
        Object.assign(this.state, {
            items: [],
            tableable: true,
            table: buildTable.call(this, options)
        })
    })

function buildTable(options) {
    switch (typeof options) {
        case 'object': return fromObject.call(this, options);
        case 'function': return fromFunction.call(this, options);
        default: throw Error('invalid tableable options');
    }
}

const def = {
    columns: [],
    buttons: [],
    filters: [],
    Actions: null
}

function fromFunction(cb = () => def) { return fromObject.call(this, cb.call(this)) };
function fromObject(options = def) { return options; };