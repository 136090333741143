import React from 'react';
import styles from './style.module.css';

const AppCol = ({ title, smallTitle, titleWeight = 3, children, style, className }) =>
    <div className={`${styles['appCol']} ${className}`} style={style}>
        <If predicate={title}>
            <div className={styles['appTitle']}>
                <HTitle weight={titleWeight}>{title}
                    <small>{smallTitle}</small>
                </HTitle>
                <HorizontalDivider />
            </div>
        </If>
        <div className={styles['appContent']}>
            {React.Children.count(children) > 1 ?
                children.map((child, i) => <div key={i}>{child}</div>) :
                children
            }
        </div>
    </div>;

const If = ({ predicate, children }) =>
    predicate ? children : null;

const AppRow = ({ title, smallTitle, titleWeight = 3, children, style, className }) =>
    <div className={`${styles['appRow']} ${className}`} style={style}>
        <If predicate={!!title}>
            <div className={styles['appTitle']}>
                <HTitle weight={titleWeight}>{title}
                    <small>{smallTitle}</small>
                </HTitle>
            </div>
            <HorizontalDivider />
        </If>
        <div className={styles['appContent']}>
            {children}
        </div>
    </div>;
;


const VerticalDivider = ({ height = '500px' }) =>
    <div className={styles['verticalDivider']} style={{ height }}></div>;
;

const AppShadowBox = ({ children }) =>
    <div className={styles['appShadowBox']}>
        {children}
    </div>;

const HorizontalDivider = ({ width }) =>
    <span className={styles['horizontalDivider']} style={{ width }}></span>;

const HTitle = ({ weight, children, style }) => {
    switch (weight) {
        case 1:
            return <h1 style={style}>{children}</h1>;
        case 2:
            return <h2 style={style}>{children}</h2>;
        case 3:
            return <h3 style={style}>{children}</h3>;
        case 4:
            return <h4 style={style}>{children}</h4>;
        case 5:
            return <h5 style={style}>{children}</h5>;
        case 6:
            return <h6 style={style}>{children}</h6>;
        default:
            return <h3 style={style}>{children}</h3>;
    }
};

export default ({ HTitle, HorizontalDivider, AppShadowBox, VerticalDivider, AppCol, AppRow, If })