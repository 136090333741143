import { useEffect, useState } from 'react';

export default (handler, deps = []) => {
    const [w, setW] = useState();

    useEffect(() => {
        setW(handler(window));
    }, deps);

    return w;
}