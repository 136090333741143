import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import TimeBox from './time-box';

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export default function () {
    const now = new Date();
    const [hours, setHours] = useState(now.getHours());
    const [minutes, setMinutes] = useState(now.getMinutes());

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            setHours(now.getHours());
            setMinutes(now.getMinutes())
        }, 1000)
        return () => clearInterval(interval)
    }, [])


    return <div className={styles['container']}>
        <div className={styles['date']}>
            {now.getDate()} {monthNames[now.getMonth()]} {now.getFullYear()}
        </div>
        <div className={styles['time-label']}>
            <TimeBox label='hours'>{hours}</TimeBox>
            <TimeBox label='minutes'>{minutes}</TimeBox>
        </div>
    </div>
}