import React, { useEffect } from 'react';
import stylesFile from './style.module.css';

export default ({isLoading, style, children, disabled, onEnter, onClick = () => { }, styles = {},buttonStyle={}, ...props }) => {
	const shouldBeDisabled = disabled || isLoading;

	useEffect(buildEnterHandler({ onEnter, onClick, shouldBeDisabled }));
	return (
        <div
            style={style}
            className={`${styles.container} ${stylesFile['container']} ${shouldBeDisabled && stylesFile['disabled']}`}
        >
            <input
                style={{'cursor': disabled ? 'default' : isLoading ? 'progress' : 'pointer' , ...buttonStyle}}
                value={children}
                className={styles.input}
                type='button'
                onClick={() => !shouldBeDisabled && onClick()}{...props}
	            disabled={disabled}
            />
        </div>
	)
}

const buildEnterHandler = handlerData => () => {
	const handler = enterHendler(handlerData);
	window.addEventListener('keyup', handler);
	return () => { window.removeEventListener('keyup', handler) }
}

const enterHendler = ({ onEnter, onClick,shouldBeDisabled }) => function ({ keyCode }) {
	onEnter && !shouldBeDisabled && keyCode === 13 && onClick()
}