import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '../pagination';
import styles from './style.module.css';

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

const DefaultComponent = ({ children, ...props }) => <div {...props}>{children}</div>;

export default function ({ items = [],
    filter,
    itemsPerPage = 10,
    className, defaultSelect = 0,
    SummeryComponent = DefaultComponent,
    DetailsComponent = DefaultComponent }) {

    const [expanded, setExpanded] = useState(defaultSelect);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);

    const displayItems = items.filter(item => filter ? filter(item, search) : item);
    const handleChange = panel => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

    const pages = displayItems.length / itemsPerPage;

    return (
        <div className={`${styles['container']} ${className}`}>
            {filter && items.length ? <TextField
                label={'Search...'}
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> : <div />}
            {displayItems
                .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
                .map((item, idx) => <ExpansionPanel square key={idx}
                    expanded={expanded === idx} onChange={handleChange(idx)}>
                    <ExpansionPanelSummary aria-controls="content">
                        <SummeryComponent item={item} />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <DetailsComponent item={item} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>)}
            {pages > 1 ? <Pagination
                page={page}
                pages={pages}
                onChange={setPage}
            /> : <div />}
        </div>
    );
}