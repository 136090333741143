import React from 'react';
import styles from './style.module.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


export default  ({label,onChange,checked,onClick = onChange , style}) =>
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={onClick}
                    value="checkedB"
                    classes={{root:styles['checkbox-root']}}
                />
            }
            label={label}
            style={{color:'#fe0072', ...style}}
            className={styles['label']}
            classes={{label:styles['label-text']}}
        />
//
// export default function ({ label,
//     onChange, checked,
//     /** aliases */
//     onClick = onChange,
//     value = checked,
//     /** elemnt controll */
//     props = {
//         container: {},
//         input: {}
//     },
//     ...rest }) {
//     return (
//         <div className={styles['container']}>
//         <div
//             className={styles['checkbox']}
//             onClick={onClick}
//             {...props.container}
//         >
//             <input
//                 onChange={() => { }}
//                 type='checkbox' {...props.input}
//             />
//             <label htmlFor={styles['checkbox']}/>
//         </div>
//         <div className={styles['label']}>yosi</div>
//     </div>
//     )
// }