import React from 'react';
import styles from './style.module.css';
import Tooltip from '@material-ui/core/Tooltip';

export default function ({
    data = [],
    colors = ['#FDC1ED', '#F180CE', '#B054AC', '#8445AD', '#6B72CF'],
    ItemRedner = defaultItemRedner,
    nameFormatter = name => name,
    valueFormatter = value => value,
    tooltip
}) {

    return <div>
        {data.map((item, idx) => <ItemRedner
            key={idx}
            tooltip={tooltip}
            item={item} color={colors[idx % colors.length]}
            nameFormatter={nameFormatter}
            valueFormatter={valueFormatter}
        />)}
    </div>
}

const defaultItemRedner = ({ item, color, nameFormatter, valueFormatter }) => <Tooltip
    disableFocusListener={!item.tooltip}
    disableHoverListener={!item.tooltip}
    disableTouchListener={!item.tooltip}
    title={item.tooltip || ''}>
    <div style={{ color }} className={styles['item-container']}>
        {item.name && <div className={styles['item-name']}>{nameFormatter(item.name)}</div>}
        {item.value && <div className={styles['item-value']}>{valueFormatter(item.value)}</div>}
    </div>
</Tooltip>