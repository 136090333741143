
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */
    
import Authenticator from './authenticator';
import Meiosis from './meiosis';
import Widgets from './widgets';

export default {
        Authenticator,		Meiosis,		Widgets
}
