export default Base => ({ httpRequest }) => ({ model }) => model(class extends Base {
    constructor(...args) {
        super('profile', ...args)

        const meta = getStoredJSON('meta');

        this.state = {
            resetToken: new URLSearchParams(window.location.search).get('hash'),
            ready: false,
            csrf: localStorage.getItem('csrf'),
            authenticated: localStorage.getItem('authenticated') === 'true',
            requiredAuths: {},
            settings: meta.settings || {},
            content: getStoredJSON('content'),
            meta,
            isLoginLoading : false,
        }
    }


    hasContent() { return !!Object.keys(this.state.content).length }

    setupEffect(...auths) {
        return () => {
            this.requireAuths(...auths);
            this.isAuthenticated();
        }
    }

    requireAuths(...auths) {
        return this.update(state => ({
            ...state,
            requiredAuths: auths.reduce((acc, auth) => ({
                ...acc, [auth]: true
            }), {})
        }))
    }

    isAuthenticated() {
        const { requiredAuths = {} } = this.state;

        return Promise.all([
            requiredAuths.userpass ? this.http.post(`/${this.baseRoute}/is_authenticated`).then(({ data }) => data.authenticated) : true,
            requiredAuths.mfa ? this.http.post(`/${this.baseRoute}/is_mfa_authenticated`).then(({ data }) => data.authenticated) : true
        ]).then(authenticators => this.update(state => ({
            ...state, ready: true,
            authenticated: Object.keys(requiredAuths).length && authenticators.every(authenticator => authenticator)
        })));
    }
})
    .from([
        httpRequest({
            name: 'getMeta', type: 'post',
            updateState: meta => state => ({ ...state, meta }),
            postRequest: storeJSON('meta')
        }),
        httpRequest({
            name: 'logout', type: 'post',
            postRequest: () => {
                document.cookie = false;
                localStorage.clear();
                window.location.reload();
            }
        }),
        httpRequest({
            name: 'login', type: 'post',
            preRequest : function(){
                this.update({isLoginLoading : true});
            },
            updateState: updateAuth('userpass', 'id', true),
            onError: function () {
                this.errorFor('invalid_login');
	            this.update({isLoginLoading : false});
            },
            postRequest: function({ csrfToken, ...meta })  {
                csrfToken && localStorage.setItem('csrf', csrfToken);
                meta && localStorage.setItem('meta', JSON.stringify(meta));
	            this.update({isLoginLoading : false});
            }
        }),
        httpRequest({
            name: 'mfa', type: 'post',
            updateState: updateAuth('mfa', 'mfaValid')
        }),
        httpRequest({
            name: 'getTheme', type: 'post',
            updateState: theme => state => ({ ...state, theme })
        }),
        httpRequest({
            name: 'getContent', type: 'post',
            // avoidRequestIfTrue: function(){
	         //    const rawContent = localStorage.getItem('content');
	         //    if(!!rawContent){
		     //        try{
			 //            const content = JSON.parse(rawContent);
			 //            this.update({content})
            //
            //             return true;
		     //        }catch(e){
			 //            console.error('invalid JSON content found on local storage')
		     //        }
	         //    }
            // },
            updateState: content => ({ content }),
            postRequest: storeJSON('content')
        }),
        httpRequest({
	        type: 'post',
            name: 'forgotPassword',
            preRequest : function(){
	            this.update({isLoginLoading : true});
            },
            onError: function () {
                this.errorFor('forgot_pass_error');
                this.update({isLoginLoading : false});
            },
            postRequest: function(res, { onSuccess }) {
	            this.update({isLoginLoading : false});
	            if(onSuccess)  {
		            this.infoFor('forgot_pass_success')
	                return onSuccess()
	            }

            }
        }),
        httpRequest({
            name: 'resetPassword', type: 'post',
            postRequest: function (response) {
                if(response.status === false){
	                this.errorFor('token_not_found');
                }
                this.update({ resetToken: null })
            }
        })
    ])

const updateAuth = (reqAuth, key, appendMeta) => data => state => ({
    ...state,
    meta: appendMeta ? { ...state.meta, ...data } : state.meta,
    requiredAuths: { ...state.requiredAuths, [reqAuth]: !!data[key] }
})

const getStoredJSON = (key, defaultValue = {}) => {
    try { return JSON.parse(localStorage.getItem(key)) || defaultValue }
    catch{ return defaultValue }
}

const storeJSON = key => value => localStorage.setItem(key, JSON.stringify(value))

