import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import widgets from '../../../widgets';
import ReplyIcon from '@material-ui/icons/Reply';

const { Input, Title, Button, Checkbox, ModeSelector } = widgets;

const modes = {
    login: 'login',
    reset_password: 'reset_password',
    forgot_password: 'forgot_password',
}

export default function ({ profile, onSubmit }) {
    return <ModeSelector initial={modes.login}>
        {setMode => <Login
            mode={modes.login}
            onForgetPassowrd={() => setMode(modes.forgot_password)}
            onResetPassword={() => setMode(modes.reset_password)}
            onSubmit={onSubmit}
            profile={profile} />}
        {setMode => <ForgotPassword
            profile={profile}
            mode={modes.forgot_password}
            back={() => setMode(modes.login)}
        />}
        {setMode => <ResetPassword
            profile={profile}
            mode={modes.reset_password}
            back={() => setMode(modes.login)}
        />}
    </ModeSelector>
}

const ForgotPassword = ({ profile, back }) => {
    const styles = useStyles();
    const [email, setEmail] = useState('');
    const { actions, state } = profile;
    const { forgotPassword } = actions;

    const validateEmail = (email = '') => {
        const at = email.indexOf('@'),
            dot = email.indexOf('.'),
            regex = {
                alphaNumberic: /^[a-z0-9]+$/i,
                alpha: /^[A-Za-z]+$/
            }

        return regex.alphaNumberic.test(email.slice(0, 1)) &&
            // ends with charcter
            regex.alpha.test(email.slice(-1)) &&
            at > -1 &&
            dot > -1 &&
            dot - at > 1
    }

    return <div>
        <div className={styles.titleContainer}>
            <Title>FORGOT PASSWORD</Title>
            <ReplyIcon
                onClick={back}
                style={{
                    position: 'absolute',
                    marginLeft: 20,
                    fill: 'white',
                    cursor: 'pointer'
                }} />
        </div>

        <div className={styles['error']}>
            {state.errors.includes('forgot_pass_error') ? 'Something didn\'t work here.. try again?' : ''}
        </div>
        <div className={styles['form']}>
            <Input value={email} placeholder='Email' onChange={setEmail} />
        </div>
        <div className={styles['button']}><Button
            onEnter disabled={!validateEmail(email)}
            onClick={() => forgotPassword({ email, onSuccess: back })}>SEND</Button></div>
    </div >
}

const Login = ({ onForgetPassowrd, onResetPassword, profile, onSubmit }) => {
    const styles = useStyles();
    const { actions, state } = profile;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = useState(false);

    useEffect(() => {
        if (profile.state.resetToken) onResetPassword()
    }, [])

    const auth = () => actions.login({ username, password })
        .then(onSubmit);

    const optionsStyle = ({ ...style } = {}) => ({
        display: 'inline-block', width: '48%', ...style
    })

    return <div>
        <Title>LOGIN</Title>
        <div className={styles['error']}>
            {state.errors.includes('invalid_login') ? 'Invalid Credentials' : ''}
        </div>
        <div className={styles['form']}>
            <Input value={username} placeholder='Username' onChange={setUsername} />
            <Input value={password} placeholder='Password' onChange={setPassword} type={showPass ? 'text' : 'password'} />
        </div>
        <div className={styles['options']}>
            <div style={optionsStyle({ marginLeft: '-8.5%' })}>
                <Checkbox label='Show password' checked={showPass} onClick={() => setShowPass(!showPass)} />
            </div>
            <div style={optionsStyle({ textAlign: 'right' })}>
                <span
                    onClick={onForgetPassowrd}
                    className={styles['link']}>Forgot password</span>
            </div>
        </div>
        <div className={styles['button']}><Button onEnter
            disabled={!username || !password}
            onClick={auth}>SIGN IN</Button></div>
    </div>
}

const ResetPassword = ({ profile, back }) => {
    const styles = useStyles();
    const [password, setPassword] = useState('');
    const [verify, setVerify] = useState('');
    const { actions, state } = profile;
    const { resetPassword } = actions;
    useEffect(() => {
        if (!state.resetToken) back()
    }, [state.resetToken])

    return <div>
        <Title>RESET PASSWORD</Title>
        <div className={styles['error']}>
            {state.errors.includes('forgot_pass_error') ? 'Something didn\'t work here.. try again?' : ''}
        </div>
        <div className={styles['form']}>
            <Input value={password} placeholder='Password' onChange={setPassword} type={'password'} />
            <Input value={verify} placeholder='Verify Password' onChange={setVerify} type={'password'} />
        </div>
        <div className={styles['button']}><Button onEnter
            disabled={!password || verify !== password}
            onClick={() => resetPassword({ password, resetToken: state.resetToken, onSuccess: back })}>RESET</Button></div>
    </div>
}