import React, { useEffect, useState } from 'react';
import useWindow from './use-window';
import Button from '../button';

export default ({
    url = 'https://login.cheq-platform.com',
    scopes = ['userpass'],
    Component = Button,
    Render = props => <Component {...props} />,
    
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const loginWindow = useWindow(
        w => isOpen && w.open(`${url}?scopes=${scopes.join(',')}`), [isOpen]);

    useEffect(() => {
        if (loginWindow.authenticated) {
            loginWindow.close()
            setIsOpen(false)
        };
    }, [loginWindow.authenticated])

    return <Render onClick={() => setIsOpen(true)} />
}