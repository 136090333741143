import React from 'react';
import useWindow from './use-window';

export default ({
    url = 'https://login.cheq-platform.com',
    returnUrl = '',
    scopes = ['userpass'],
    Render = () => <div />
}) => {
    useWindow(w => w.location.replace(`${url}?scopes=${scopes.join(',')}&returnUrl=${returnUrl}`))
    return <Render />
}