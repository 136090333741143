/* Observable\Meiosis Pattern
 *
 * for more information read about the meiosis pattern & obsrvables
 * https://meiosis.js.org/docs/the-meiosis-pattern.html
 * Barash 29/05/2019
 */

import flyd from 'flyd';
import { debounce } from './operators';

const buildModel = (buildValue = m => m) => (initial, model) => ({
    ...initial, [model.getName()]: buildValue(model)
});

export default (viewModels, fps = 1000 / 60) => {
    const update = flyd.stream();

    viewModels.forEach(vm => vm.setStream(update))

    const app = {
        Initial: () => viewModels.reduce(buildModel(m => m.getState()), {}),
        Actions: () => viewModels.reduce(buildModel(m => m.getActions()), {}),
    };

    const actions = app.Actions();
    const states = flyd.scan((state, [name, mutation]) => Object.assign(state, { [name]: mutation(state[name]) }), app.Initial(), update)
        .pipe(debounce(fps))

    return { states, actions, };
};
