
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */
    
import create from './create';
import del from './del';
import formable from './formable';
import getAll from './getAll';
import getById from './getById';
import httpRequest from './httpRequest';
import tableable from './tableable';
import update from './update';

export default {
        create,		del,		formable,		getAll,		getById,		httpRequest,		tableable,		update
}
